.App-body {
  background-color: #0a0a0a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: lightgray;
  text-align: left;
  max-width: available;
  margin: auto;
  overflow: scroll;
  padding: 10%;
}

.fancy-wrapper {
  font-size: 1.0rem;
  width: 90%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  div {
    width: 50%;
    line-height: 100%;

    .intro-text {
      width: 80%;
      overflow-wrap: anywhere;

      .single-letter {
        display: inline-block;
        transform-origin: 50% 84%;
      }
    }
  }
}